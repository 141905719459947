import React from "react";
import NavBar from "../Components/NavBar";
import "./about_us.css";
import Footer from "../Components/Home/Footer";

const AboutUs = () => {
return (
<>
<div className="flex flex-col items-center text-justify Background1 pb-[8rem] h-[100%] w-screen">
<NavBar />
<div className=" text-white flex flex-col items-center w-100 mt-[2rem] p-0 overflow-x-hidden w-[90vw] max-w-[80rem] ">
<h1 className="sans-sarif text-[4rem] text-center mt-9">About Us</h1>
<h3 className="text-[2rem] max450:text-[1.8rem] font-poppins text-left">KNOW WHAT WE ARE</h3>
<p className="mt-8 p-[10px]">
  Michigan Odissi Dance Academy is a Michigan based dance institute founded by Manasi Mishra. Michigan Odissi Dance Academy classes are held at Farmington Hills for Detroit area and at Okemos for Lansing Area. Training in pure classical Odissi and Odissi folk is provided by Manasi Mishra. Lessons in Yoga are given from the very first day to acquire physical fitness and suppleness which are very essential for a classical dancer. Students are also introduced to the knowledge of techniques of dance related make-up, costumes, hair-styling, music, rhythms and bols. Students are also equipped with the knowledge of Natya Shastra, thus grooming into a complete dancer after 5-6 years of rigorous training. Please Contact Manasi for details on Odissi training. 
</p>
<p className="text-center mt-8 h-100 text-[1.3rem]">
Manasi Mishra
</p>
<p className="text-center h-100 text-[1rem]">
Founder & Director
</p>
<p className="mt-5 p-[10px]">
   Manasi, a widely traveled danseuse, has cultivated a lifelong devotion to Classical Indian Dance. Born and brought up in Balasore, Orissa, she began her training in Odissi dance at the age of five, at the Nrutya Sangeeta Kala Mandira Balasore, under the Guru Deva Prasad Das and Guru Sudhakar Sahu. She earned the degrees ‘Nritya Sree’ and ‘Nritya Visharad’. She also studied ‘Sambalpuri’ and various other folk dance forms. An MA in History from Utkal University, she obtained the best dancer award several times from Utkal University and won awards for various other performances. For the last seventeen years she is teaching Odissi dance to young aspiring dancers in the US. She has performed in various cities in India and USA.
</p>
{/* <p className="mt-5">
 At HappyPrancer.com, we believe that fitness can be fun and enjoyable, and we are committed to helping people lead healthier, happier lives, especially during these challenging times. Our website offers a holistic approach to fitness, incorporating mindfulness and self-care practices into our resources. We understand that fitness is not just about physical health, but also about mental and emotional well-being. 
</p>
<p className="mt-6 mb-20">
Our team of passionate individuals is dedicated to spreading happiness and promoting a positive outlook on life. We believe that everyone deserves to feel good about themselves and that everyone has the potential to lead a happy, healthy life. Join us on our journey to spread positivity and inspire people to live their best lives with the fusion of Zumba and BWORKZ dance fitness routines 
</p> */}
{/* <div className="my-8 text-[1.2rem] flex flex-col gap-6 w-[90vw] max-w-[60rem] border-[0]">
<h1>WATCH MORE ON OUR YOUTUBE</h1>
<div>
<iframe
// width="844"
// height="515"
src="https://www.youtube.com/embed/pPzIbKB2GNQ"
className="w-[80vw] h-[45vw]"
title="YouTube video player"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
allowFullScreen
></iframe>
</div>
</div> */}
</div>
</div>
{/* <div className="w-full h-[1px] bg-[#dd934a]"></div> */}
<Footer />
</>
);
};

export default AboutUs;

