import React, { useContext, useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import NavBar from "../Components/NavBar";
// import DanceAuth from "../Utils/Png/danceAuth.png";
import Context from "../Context/Context";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Country from "../Components/Auth/Country";

const SignUp = () => {
  const [firstName, setFirstName] = useState(""); // Added for first name
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [country, setCountry] = useState("India");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newUser, setNewUser] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState(0);
  const [err, setErr] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const data = {
    Otp_Msg: `An OTP has been sent to +${email}. Please check your inbox, and in case you don’t find it there, kindly review the spam folder.`,
  };
  // const data = {
  //   Otp_Msg: `An OTP has been sent to +${countryCode}${phoneNumber}. Please check your inbox, and in case you don’t find it there, kindly review the spam folder.`,
  // };
  const UtilCtx = useContext(Context).util;
  const UserCtx = useContext(Context);
  const Navigate = useNavigate();

  const [counter, setCounter] = useState(60); // Timer counter
  const [resendVisible, setResendVisible] = useState(false); // Resend OTP visibility

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("newuser") === "false") {
      setIsNewUser(false);
    }
  }, []);

  // Function to handle resend OTP
  const resendOTP = async (event) => {
    event.preventDefault();
    try {
      // if (phoneNumber) {
      // await Auth.resendSignUp(`+${countryCode}${phoneNumber}`);
      if (email) {
        await Auth.resendSignUp(email);
        setCounter(60); // Reset the timer
        setResendVisible(false); // Hide the resend button
        setErr("OTP resent successfully."); // Provide appropriate feedback to the user
      } else {
        setErr("Please enter your Phone Number."); // Provide appropriate feedback to the user
        // setErr("Please enter your Email Id."); // Provide appropriate feedback to the user
      }
    } catch (error) {
      setErr(error.message);
    }
  };

  useEffect(() => {
    let timer = null;
    if (counter > 0) {
      timer = setInterval(
        () => setCounter((prevCounter) => prevCounter - 1),
        1000
      );
    } else {
      setResendVisible(true);
    }

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const passwordVisibilityChange = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  const form1Validator = () => {
    if (firstName.length === 0) {
      setErr("Enter the Name");
      return false;
    } else if (phoneNumber.length < 10) {
      setErr("Enter a Valid Phone Number");
      return false;
    } else if (countryCode.length === 0) {
      setErr("Choose Country");
      return false;
    } else if (!(email.includes("@") && email.includes("."))) {
      setErr("Enter a Valid Email");
      return false;
    } else if (country.length === 0) {
      setErr("Enter a Country Name");
      return false;
    } else if (password.length < 8) {
      setErr("Password is too Short");
      return false;
    } else if (password !== confirmPassword) {
      setErr("Password Doesn't Match");
      return false;
    } else {
      setErr("");
      return true;
    }
  };

  const form2Validator = () => {
    if (confirmationCode.length === 0) {
      setErr("Enter a Valid Code");
      return false;
    } else {
      setErr("");
      return true;
    }
  };

  const userExistPhoneNumberSignUp = async () => {
    try {
      console.log("Sign in");
      await Auth.signIn(`+${countryCode}${phoneNumber}`, password);
      console.log("post");
      await API.post("user", "/user/profile/moda", {
        body: {
          emailId: email,
          userName: `${firstName} ${lastName}`,
          phoneNumber: phoneNumber,
          country: country,
        },
      });
      const userdata = await API.get("user", "/user/profile/moda");
      //Temporary
      // userdata.Status = true;
      UserCtx.setUserData(userdata);
      UserCtx.setIsAuth(true);
      UtilCtx.setLoader(false);
      alert("Signed Up");
      if (userdata.status === "Active") {
        UtilCtx.setLoader(false);
        Navigate("/dashboard");
      }
      UtilCtx.setLoader(false);
      Navigate("/subscription");
    } catch (error) {
      UtilCtx.setLoader(false);
      if (error.message === "Incorrect username or password.") {
        console.log("Phone Number User Doesn't Exist");
        await userExistEmailIdSignUp();
      }
      throw error;
    } finally {
      UtilCtx.setLoader(false);
    }
  };

  const userExistEmailIdSignUp = async () => {
    try {
      console.log("Sign in");
      await Auth.signIn(email, password);
      console.log("post");
      await API.post("user", "/user/profile/moda", {
        body: {
          emailId: email,
          userName: `${firstName} ${lastName}`,
          phoneNumber: phoneNumber,
          country: country,
        },
      });
      const userdata = await API.get("user", "/user/profile/moda");
      //Temporary
      // userdata.Status = true;
      UserCtx.setUserData(userdata);
      UserCtx.setIsAuth(true);
      UtilCtx.setLoader(false);
      alert("Signed Up");
      if (userdata.status === "Active") {
        UtilCtx.setLoader(false);
        Navigate("/dashboard");
      }
      UtilCtx.setLoader(false);
      Navigate("/subscription");
    } catch (error) {
      UtilCtx.setLoader(false);
      console.log("Error:", error.message);
      throw error;
    } finally {
      UtilCtx.setLoader(false);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    UtilCtx.setLoader(true);

    try {
      if (form1Validator()) {
        if (!isNewUser) {
          await userExistPhoneNumberSignUp();
          await userExistEmailIdSignUp();
          UtilCtx.setLoader(false);
          return;
        }
        console.log(phoneNumber);
        const newUserCheck = await Auth.signUp({
          // username: `+${countryCode}${phoneNumber}`,
          username: email,
          password: password,
          attributes: {
            phone_number: `+${countryCode}${phoneNumber}`,
            given_name: `${firstName} ${lastName}`,
            email: email,
          },
        });
        setNewUser(newUserCheck);
      }
      UtilCtx.setLoader(false);
    } catch (e) {
      setErr(e.message);
      UtilCtx.setLoader(false);
    }
  };

  const onConfirmationSubmit = async (event) => {
    event.preventDefault();

    UtilCtx.setLoader(true);

    try {
      if (form2Validator()) {
        await Auth.confirmSignUp(
          // `+${countryCode}${phoneNumber}`,
          email,
          confirmationCode
        );
        // await Auth.signIn(`+${countryCode}${phoneNumber}`, password);
        await Auth.signIn(email, password);
        await API.post("user", "/user/profile/moda", {
          body: {
            emailId: email,
            userName: `${firstName} ${lastName}`,
            phoneNumber: `${countryCode}${phoneNumber}`,
            country: country,
          },
        });
        const userdata = await API.get("user", "/user/profile/moda");
        //Temporary
        // userdata.Status = true;
        UserCtx.setUserData(userdata);
        UserCtx.setIsAuth(true);
        UtilCtx.setLoader(false);
        alert("Signed Up");
        // if (userdata.status === "Active") {
        //   Navigate("/dashboard");
        // }
        Navigate("/dashboard");
      } else {
        UtilCtx.setLoader(false);
      }
      UtilCtx.setLoader(false);
    } catch (e) {
      setErr(e.message);
      UtilCtx.setLoader(false);
    }
  };
  const loginContainerStyle = {
    width: "100%",
    marginTop: "1.5rem",
    paddingBottom: "3rem",
    borderBottom: "2px solid #DD934A",
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.936), rgba(0, 0, 0, 0.36)), url(https://images.unsplash.com/photo-1549576490-b0b4831ef60a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)`,
    // height: "100%",
    backgroundSize: "cover",
    // width:"100rem",
    height: "55rem",
  };
  const form1 = () => {
    return (
      <form className="w-[50vw] max800:w-[90vw] max-w-[29rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center ">
        <h3 className="text-[1.1rem] font-[700]">Sign Up</h3>
        <div className="text-center">
          <p className="w-[13.5rem] font-[500] my-2 text-[0.8rem]">
            Unlock Your Potential, Sign Up Today for a Healthier Tomorrow!
          </p>
        </div>
        <ul className="flex flex-col items-center px-0">
          <li className="flex items-center gap-1 mt-8 max500:flex-col max500:gap-2 max500:items-start">
            <input
              className="w-[9.5rem] border-[2px] px-3 py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw] max500:mb-4"
              value={firstName}
              placeholder="First Name"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <input
              className="w-[9.5rem] border-[2px] px-3 py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw]"
              value={lastName}
              placeholder="Last Name"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </li>
          <li className="flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative">
            <div className="relative">
              <input
                className="w-[19.5rem] border-[2px] px-6 py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw]"
                type="text"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setEmail(inputValue);
                }}
              />
            </div>
          </li>
          <li className="flex gap-20 mt-2 ml-6 max500:flex-col max500:gap-2 max500:items-start relative">
            <select
              value={countryCode}
              name="countryCode"
              id=""
              className="w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw] mt-6"
              onChange={(e) => {
                let countries = e.target.innerText.split("\n");
                const countryCodes = [];
                countries = countries.map((item) => {
                  countryCodes.push(item.split(" (")[1].split(")")[0]);
                  return item.split(" (")[0];
                });

                setCountry(
                  countries[countryCodes.indexOf(`+${e.target.value}`)]
                );
                setCountryCode(e.target.value.toString());
              }}
            >
              {<Country />}
            </select>
          </li>
          <li className="flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative">
            <div className="relative">
              <input
                className="w-[19.5rem] border-[2px] px-6 py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw]"
                type="number"
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length >= 0 && inputValue.length <= 10) {
                    setPhoneNumber(inputValue.toString());
                  }
                }}
              />
            </div>
          </li>
          <li className="flex items-center gap-1 mt-6 max500:flex-col max500:gap-2 max500:items-start">
            <input
              className="w-[19.5rem] border-[2px] px-3 py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw]"
              type={"password"}
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </li>
          <li className="flex items-center gap-1 mt-6 max500:flex-col max500:gap-2 max500:items-start relative">
            <input
              className="w-[19.5rem] border-[2px] px-3 py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw]"
              type={!passwordVisible && "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            {passwordVisible ? (
              <AiFillEye
                onClick={passwordVisibilityChange}
                className="absolute right-4 max500:mt-3"
                size={"1.25rem"}
              />
            ) : (
              <AiFillEyeInvisible
                onClick={passwordVisibilityChange}
                className="absolute right-4 max500:mt-3"
                size={"1.25rem"}
              />
            )}
          </li>
        </ul>
        {err && <p className="text-[0.8rem]  mt-2 text-red-500">{err}</p>}
        <button
          className="w-[19.5rem] py-2 mt-3 mb-3 bg-[#DD934A] text-white rounded-2 max500:w-[80vw]"
          onClick={onSubmit}
        >
          Sign Up
        </button>
        <p
          className=" text-[0.85rem] text-black cursor-pointer"
          onClick={() => {
            Navigate("/login");
          }}
        >
          Already logged In ?{" "}
          <span className="font-[500] text-[#DD934A]">Sign In</span>{" "}
        </p>
      </form>
    );
  };

  const form2 = () => {
    return (
      <form className="w-[50vw] max800:w-[90vw] max-w-[35rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center ">
        <h3 className="text-[1.2rem] font-roboto font-bold">Sign Up</h3>
        <ul className="flex flex-col items-center">
          <li className="flex items-center gap-20 mt-8 max500:flex-col max500:gap-2 max500:items-start">
            <label className="w-20 max500:ml-3">OTP Code</label>
            <ValidatorForm>
              <TextValidator
                label={
                  <span style={{ color: "#DD934A" }}>Enter 6 Digit OTP</span>
                }
                variant="outlined"
                inputProps={{ maxLength: 6 }}
                name="otp"
                size="small"
                type="text"
                fullWidth
                validators={["required"]}
                errorMessages={["OTP is required"]}
                value={confirmationCode === 0 ? "" : confirmationCode}
                onChange={(e) => {
                  setConfirmationCode(e.target.value);
                }}
              />
            </ValidatorForm>
          </li>
          {resendVisible ? (
            <button className="mt-[1rem] ml-[5rem]" onClick={resendOTP}>
              Resend OTP
            </button>
          ) : (
            <p className="mt-[1rem]">
              Resend OTP in{" "}
              <span className="text-[#DD934A] font-bold">{counter}</span>{" "}
              seconds
            </p>
          )}
        </ul>
        {err && <p className="text-[0.8rem] mt-2 text-red-500">{err}</p>}
        <p className="text-center w-[80%] text-[0.81rem]">
          <strong className="text-red-500">Note*</strong>
          {data.Otp_Msg}
        </p>
        <button
          className="p-4 py-1 mt-6 mb-3 text-white bg-[#DD934A] rounded-lg"
          onClick={onConfirmationSubmit}
        >
          Confirm code
        </button>
      </form>
    );
  };

  return (
    <div className="w-screen min-h-screen bg-[#f0efef]">
      <NavBar />
      <div
        className="flex flex-col items-center mt-6 text-black"
        style={loginContainerStyle}
      >
        {/* <h3 className="text-[2rem]">FITNESS</h3>
<div className="w-[80%] h-[0.08rem] bg-black flex"></div> */}
        <div className="flex w-[100%] gap-16 justify-center items-end mt-16 ">
          {!newUser ? form1() : form2()}
          {/* <img src={DanceAuth} alt="Dance" className="w-[20%] max800:hidden" /> */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
