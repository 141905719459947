import React from "react";
import Head from "../../Utils/ODISSI/Head.png";
import "./Header.css";
const Header = () => {

return (
<div className=" flex items-center justify-center h-[36rem] pb-20">
<div className="absolute z-10 flex flex-col items-center w-screen content ">
<div className="w-[auto] text-left flex">
<h1 className="w-full max1250:w-[50%] max536:w-[90vw] max800:w-[80%]  text-[5.7rem] max800:text-[3.8rem] max1250:text-[4.5rem] text-[#fff] ">

</h1>
</div>
</div>
<div className="-z-10 ">
<div

className="object-cover object-top h-[36rem] w-screen -z-10  max-w-screen"
>
<img src={Head} className="object-cover bg-[#000000] mt-[2.5rem] h-[100%] w-screen max-w-screen mb-[20px] " alt="" />
</div>

<h5  className="m-0  w-full flex items-center justify-center  bg-black  h-[5rem] shrink-0 text-[1rem] font-format">
<p className="Fussion1 text-center  text-[1.5rem] max375:text-[1rem] text-white">
Embrace Tradition, Unleash Grace: Michigan Odissi Dance Academy
</p>
</h5>

</div>
</div>
);
};

export default Header;
