import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import "../comp/Subscription.css";

export default function Subscription() {
const ref = useRef(null);
const [, setIsInView] = useState(false); // Destructure the first element, which you're not using

useEffect(() => {
const handleScroll = () => {
const { top } = ref.current.getBoundingClientRect();
setIsInView(top <= window.innerHeight);
};

window.addEventListener("scroll", handleScroll);
return () => {
window.removeEventListener("scroll", handleScroll);
};
}, []);

const Navigate = useNavigate();

const subscriptionDetails = [
{
title: "Subscribe",
price: "$80",
description: "Studio Group Class Monthly",
},
{
title: "Subscribe",
price: "$60",
description: "At Home Group Class Monthly",
},
{
title: "Subscribe",
price: "$140",
description: "Studio Group Class Monthly (Mother & Daughter)",
},
{
title: "Subscribe",
price: "$120",
description: "At Home Group Class Monthly (Mother & Daughter)",
},
{
title: "Subscribe",
price: "$120",
description: "Private Class Monthly",
},
];

return (
<>
<section className="Back text-[1.5rem] py-[5rem] flex flex-col items-center h-[140rem] max1414:h-[auto] justify-center gap-[5rem]">
<div className="text-center text-[white] text-[3.4rem] max500:text-[2rem] mt-20 font-bebas-neue">
<div
ref={ref}
style={{ position: "relative", overflow: "hidden" }}
>
<div>
<h1 className="font-Dosis font-bold">
CHECK OUT OUR EXCLUSIVE OFFER!
</h1>
</div>
<div

/>
</div>
<div
ref={ref}
style={{ position: "relative", overflow: "hidden" }}
>
<div>
<h3 className="text-[1.5rem] max500:text-[1rem] font-Dosis">
see what are the pricing in details
</h3>
</div>
<div


/>
</div>
</div>
<div className="flex flex-wrap justify-center items-center w-full max-w-[80rem] gap-28 max800:flex-col">
<div className="flex flex-wrap w-full gap-[10rem] justify-center items-center text-center">
{subscriptionDetails.map((subscription, index) => (
<div
key={index}
style={{ position: "relative", overflow: "hidden" }}
>
<div>
<div className="bg-white w-[30rem] text-center h-[32rem] rounded-[2rem] flex flex-col justify-center items-center gap-4 shadowSubscribe max500:w-[90vw] max450:gap-[1.2rem] max450:text-[1rem] max450:p-12 border-[0.1rem]">
<p className="font-gidugu text-center bg-gradient-radial flex items-center justify-center leading-[1.5rem] h-[107px] w-[303px] max500:text-[1.9rem] text-[2rem] font-Dosis mb-[1.2rem]">
{subscription.title}
</p>
<div className="flex w-[17rem] flex-col text-center gap-5 items-center font-Dosis">
<ul className="list-disc text-[1rem]">
<li>
<p className="font-poppins">
{subscription.description} -{" "}
<span className="font-bold">
{subscription.price}
</span>
</p>
</li>
</ul>
</div>
<button
onClick={() => {
Navigate("/query");
}}
className="w-[15rem] leading-0 textbox px-12 py-2 font-russo rounded-[11px] mt-[2rem] hover:text-[#ffff] hover:bg-[#DD934A] border-[#DD934A] border-[1px] text-[#DD934A] h-[3rem] flex justify-center items-center max450:w-[40vw]"
>
Continue
</button>
</div>
</div>
</div>
))}
</div>
</div>
</section>
</>
);
}

