import React, { useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import "./userprofile.css";
import Context from "../../Context/Context";
import { useNavigate } from "react-router-dom";

const UserProfile = ({
isUserAdd,
updateUserInList,
isOpen,
onClose,
user,
}) => {
const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [phoneNumber, setPhoneNumber] = useState("");
const [status, setStatus] = useState("");
const [balance, setBalance] = useState("");
const UtilCtx = useContext(Context).util;
const Ctx = useContext(Context);
const [cognitoId, setCognitoId] = useState("");
const Navigate = useNavigate();
const [lastMonthZPoints, setLastMonthZPoints] = useState("");
const [currentMonthZPoints, setCurrentMonthZPoints] = useState("");
const phoneRegexPattern = /^[0-9]{10}$/; // Change this pattern as needed for your specific validation criteria

// const [cognitoId, setCognitoId] = useState("");
const formatDate = (epochDate) => {
const date = new Date(epochDate);
const day = String(date.getDate()).padStart(2, "0");
const month = String(date.getMonth() + 1).padStart(2, "0");
const year = date.getFullYear();
return `${day}/${month}/${year}`;
};
console.log("Received user prop:", user);

useEffect(() => {
setName(user && (user.userName ? user.userName : ""));
setEmail(user && (user.emailId ? user.emailId : ""));
setPhoneNumber(user && (user.phoneNumber ? user.phoneNumber : ""));
setStatus(user && (user.status ? user.status : ""));
setBalance(user && (user.balance ? user.balance : ""));
setCognitoId(user && (user.cognitoId ? user.cognitoId : ""));
setLastMonthZPoints(
user && (user.lastMonthZPoints ? user.lastMonthZPoints : "0")
);
setCurrentMonthZPoints(
user && (user.currentMonthZPoints ? user.currentMonthZPoints : "0")
);
}, [user]);

const onUpdateUser = async (e) => {
    e.preventDefault();
  
    if (!(name && email && phoneNumber && status && balance)) {
      alert("Fill all Fields");
      return;
    }
    if (!name) {
      alert("Fill Name");
      return;
    } else if (!email) {
      alert("Fill email");
      return;
    } else if (!phoneRegexPattern.test(phoneNumber)) {
      alert("Enter a valid 10-digit phone number");
      return;
    } else if (!status) {
      alert("Fill Status");
      return;
    } else if (!balance) {
      alert("Fill Balance");
      return;
    }

UtilCtx.setLoader(true);

try {
console.log("started");

await API.put("user", "/admin/update-user/moda", {
body: {
cognitoId: cognitoId,
emailId: email,
userName: name,
phoneNumber: phoneNumber,
status: status,
balance: balance,
currentMonthZPoints: currentMonthZPoints,
lastMonthZPoints: lastMonthZPoints,
},
});

const Users = Ctx.userList.map((item)=>{
if(item.cognitoId === cognitoId){
item.emailId = email;
item.userName = name;
item.phoneNumber = phoneNumber;
item.status = status;
item.balance = balance;
item.currentMonthZPoints = currentMonthZPoints;
item.lastMonthZPoints = lastMonthZPoints;

return item;
}else return item;
})

UtilCtx.setLoader(false);

Ctx.setUserList(Users);

alert("User Updated");


onClose();
} catch (e) {
console.log(e);
UtilCtx.setLoader(false);
}
};

useEffect(() => {
if (Ctx.isUserDataLoaded) {
if (Ctx.userData.userType !== "admin") {
Navigate("/");
}
}
}, [Ctx, Navigate]);

const sendReminder = async (cognitoId) => {
UtilCtx.setLoader(true);

const pa = "moda@ybl";
const pn = "moda";
const am = 10;

try {
const res = await API.post("user", `/user/send-email/moda`, {
body: {
pa,
pn,
am,
cognitoId,
},
});

console.log(res);

alert(res.message);
UtilCtx.setLoader(false);
} catch (e) {
console.log(e);
UtilCtx.setLoader(false);
}
};

if (!isOpen) return null;

return (
<div className="modal">
<div className="modal-content flex items-center justify-center ">
<div className="">
<div className="w-[100%] max1050:w-[100%] max-w-[36rem] bg-[#eceaeae1] rounded-3xl p-3 flex flex-col items-center max536:w-[90%]">
<form className="mt-6 flex flex-col gap-8 max560:w-full">
{/* Name */}
<div className="flex justify-center">
<label className="ml-2">Name</label>
</div>
<div className="flex justify-center">
<input
required
placeholder="Name"
className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
type={"text"}
value={name}
onChange={(e) => {
setName(e.target.value);
}}
/>
</div>

{/* Email and Phone Number */}
<div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
<div className="flex flex-col gap-1 justify-center">
<label className="ml-2">Email</label>
<input
className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
type="email"
value={email}
onChange={(e) => {
setEmail(e.target.value);
}}
/>
</div>

<div className="flex flex-col gap-1 justify-center">
<label className="ml-2">Phone Number</label>
<input
className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
type="text"
mber
value={phoneNumber}
onChange={(e) => {
setPhoneNumber(e.target.value);
}}
/>
</div>
</div>

{/* Attendance and Joining Date */}
<div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
<div className="flex flex-col gap-1 justify-center">
<label className="ml-2">Attendance</label>
<div className="flex items-center justify-center w-[70%]">
<input
className="bg-[#c2bfbf81] text-[#0008] px-2 py-2 rounded-lg"
type="number"
value={currentMonthZPoints}
onChange={(e) => {
setCurrentMonthZPoints(e.target.value)
}}
/>
<p className="mt-3 mx-2">/</p>
<input
className="bg-[#c2bfbf81] text-[#0008] px-2 py-2 rounded-lg "
type="number"
value={lastMonthZPoints}
onChange={(e) => {
setLastMonthZPoints(e.target.value)
// const current = e.target.value.split("/")[0];
// const last = e.target.value.split("/")[1];
// if (isNaN(parseInt(current))) {
//   setCurrentMonthZPoints(0);
// } else {
//   setCurrentMonthZPoints(parseInt(current).toString());
// }
// if (isNaN(parseInt(last))) {
//   setLastMonthZPoints(0);
// } else {
//   setLastMonthZPoints(parseInt(last).toString());
// }
}}
/>
</div>
</div>

<div className="flex flex-col gap-1 justify-center">
<label className="ml-2">Joining Date</label>
<input
className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
type="text"
value={formatDate(user.joiningDate)}
// onChange={(e) => setJoiningDate(e.target.value)}
/>
</div>
</div>

{/* User Status and Due */}
<div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
<div className="flex flex-col gap-1 justify-center">
<label className="ml-2">User Status</label>
<select
className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
value={status}
onChange={(e) => {
setStatus(e.target.value);
}}
>
<option value="Active">Active</option>
<option value="InActive">InActive</option>
</select>
</div>

<div className="flex flex-col gap-1 justify-center">
<label className="ml-2">Due</label>
<input
className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
type="text"
value={balance}
onChange={(e) => {
setBalance(e.target.value); // Use setBalance to update balance state
}}
/>
</div>
</div>

<div className="flex justify-center">
{/* Send Invoice Button */}
<button
className="bg-[#1b7571] rounded-lg py-2 mr-4" //
onClick={(e) => {
e.preventDefault();
sendReminder(cognitoId);
}}
>
Send Invoice
</button>

{/* Update Profile Button */}
<button
className="bg-[#c2bfbf81] rounded-lg py-2"
onClick={(e) => {
console.log("Before onUpdateUser");
onUpdateUser(e);
console.log("After onUpdateUser");
onClose();
}}
>
Update Profile
</button>
</div>

{/* Close Button */}
<div className="flex justify-center">
<button onClick={onClose}>Close</button>
</div>
</form>
</div>
</div>
</div>
</div>
);
};

export default UserProfile;


// import React, { useContext, useEffect, useState } from "react";
// import { API } from "aws-amplify";
// import "./userprofile.css";
// import Context from "../../Context/Context";
// import { useNavigate } from "react-router-dom";
// import CloseIcon from '@mui/icons-material/Close';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEnvelope, faUserEdit } from '@fortawesome/free-solid-svg-icons';

// const UserProfile = ({
//   isUserAdd,
//   updateUserInList,
//   isOpen,
//   onClose,
//   user,
// }) => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [status, setStatus] = useState("");
//   const [balance, setBalance] = useState("");
//   const [country, setCountry] = useState(user && user.country ? user.country : "");
//   const UtilCtx = useContext(Context).util;
//   const Ctx = useContext(Context);
//   const [cognitoId, setCognitoId] = useState("");
//   const Navigate = useNavigate();
//   const [lastMonthZPoints, setLastMonthZPoints] = useState("");
//   const [currentMonthZPoints, setCurrentMonthZPoints] = useState("");
//   // const [cognitoId, setCognitoId] = useState("");
//   const formatDate = (epochDate) => {
//     const date = new Date(epochDate);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     return `${day}/${month}/${year}`;
//   };
//   console.log("Received user prop:", user);

//   useEffect(() => {
//     setName(user && (user.userName ? user.userName : ""));
//     setEmail(user && (user.emailId ? user.emailId : ""));
//     setPhoneNumber(user && (user.phoneNumber ? user.phoneNumber : ""));
//     setStatus(user && (user.status ? user.status : ""));
//     setBalance(user && (user.balance ? user.balance : ""));
//     setCognitoId(user && (user.cognitoId ? user.cognitoId : ""));
//     setLastMonthZPoints(
//       user && (user.lastMonthZPoints ? user.lastMonthZPoints : "0")
//     );
//     setCurrentMonthZPoints(
//       user && (user.currentMonthZPoints ? user.currentMonthZPoints : "0")
//     );
//     setCountry(user && (user.country ? user.country : ""))
//   }, [user]);

//   const onUpdateUser = async (e) => {
//     e.preventDefault();

//     if (!(name && email && phoneNumber && status && balance && country)) {
//       alert("Fill all Fields");
//       return;
//     }
//     if (!name) {
//       alert("Fill Name");
//       return;
//     } else if (!email) {
//       alert("Fill email");
//       return;
//     } else if (!phoneNumber) {
//       alert("Fill Phone Number");
//       return;
//     } else if (!status) {
//       alert("Fill Status");
//       return;
//     } else if (!balance) {
//       alert("Fill Balance");

//       return;
//     }

//     UtilCtx.setLoader(true);

//     try {
//       console.log("started");

//       await API.put("user", "/admin/update-user/moda", {
//         body: {
//           cognitoId: cognitoId,
//           emailId: email,
//           userName: name,
//           phoneNumber: phoneNumber,
//           status: status,
//           balance: balance,
//           country: country,
//           currentMonthZPoints: currentMonthZPoints,
//           lastMonthZPoints: lastMonthZPoints,
//         },
//       });

//       alert("User Updated");

//       UtilCtx.setLoader(false);

//       const userList = Ctx.userList.map((item) => {
//         if (item.cognitoId === cognitoId) {
//           item.emailId = email;
//           item.userName = name;
//           item.phoneNumber = phoneNumber;
//           item.status = status;
//           item.balance = balance;
//           item.currentMonthZPoints = currentMonthZPoints;
//           item.lastMonthZPoints = lastMonthZPoints;
//           item.country = country;
//           return item;
//         } else {
//           return item;
//         }
//       })

//       Ctx.setUserList(userList);

//       // updateUserInList({
//       //   ...user,
//       //   userName: name,
//       //   emailId: email,
//       //   phoneNumber: phoneNumber,
//       //   status: status,
//       //   balance: balance,
//       //   currentMonthZPoints: currentMonthZPoints,
//       //   lastMonthZPoints: lastMonthZPoints,
//       // });

//       onClose();
//     } catch (e) {
//       console.log(e);
//       UtilCtx.setLoader(false);
//     }
//   };

//   useEffect(() => {
//     if (Ctx.isUserDataLoaded) {
//       if (Ctx.userData.userType !== "admin") {
//         Navigate("/");
//       }
//     }
//   }, [Ctx, Navigate]);
//   const getWhatsappUrl = () => {
//     const currentMonth = new Date().toLocaleString('default', { month: 'long' });
//     const message = `Payment screenshot for ${currentMonth}`;

//     const whatsappUrl = `https://wa.me/7077993547?text=${encodeURIComponent(message)}`;
//     return whatsappUrl;
// };

//   const sendReminder = async (cognitoId) => {
//     UtilCtx.setLoader(true);

//     const pa = "moda@ybl";
//     // const pn = "moda";
//     // const am = 10;

//     try {
//       const res = await API.post("user", `/user/send-email/moda`, {
//         body: {
//           pa,
//           paymentPhoneNumber: '(517) 290-3720',
//           whatsappUrl : getWhatsappUrl(),
//           qrLink : 'https://institution-qr-code.s3.amazonaws.com/moda/QRCode.jpg',
//           cognitoId,
//         },
//       });

//       console.log(res);

//       alert(res.message);
//       UtilCtx.setLoader(false);
//       onClose();
//     } catch (e) {
//       console.log(e);
//       UtilCtx.setLoader(false);
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="modal ">
//       <div className="modal-content flex items-center justify-center ">
//         <div className="">
//           <div className="w-[100%] max1050:w-[100%] max-w-[36rem] bg-[#eceaeac7] rounded-3xl p-3 flex flex-col items-center max536:w-[90%]">
//             <form className="mt-6 flex flex-col gap-8 max560:w-full">
//               {/* Name */}
//               <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
//                 <div className="flex flex-col gap-1 justify-center">
//                   <label className="ml-2">Name</label>
//                   <input
//                     required
//                     placeholder="Name"
//                     className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
//                     type={"text"}
//                     value={name}
//                     onChange={(e) => {
//                       setName(e.target.value);
//                     }}
//                   />
//                 </div>

//                 <div className="flex flex-col gap-1 justify-center">
//                   <label className="ml-2">Email</label>
//                   <input
//                     className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
//                     type="email"
//                     value={email}
//                     onChange={(e) => {
//                       setEmail(e.target.value);
//                     }}
//                   />
//                 </div>
//               </div>

//               <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
//                 <div className="flex flex-col gap-1 justify-center">
//                   <label className="ml-2">Country</label>
//                   <input
//                     className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
//                     type="text"
//                     value={country}
//                     onChange={(e) => {
//                       setCountry(e.target.value);
//                     }}
//                   />
//                 </div>

//                 <div className="flex flex-col gap-1 justify-center">
//                   <label className="ml-2">Phone Number</label>
//                   <input
//                     className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
//                     type="text"
//                     value={phoneNumber}
//                     onChange={(e) => {
//                       setPhoneNumber(e.target.value);
//                     }}
//                   />
//                 </div>
//               </div>

//               {/* Attendance and Joining Date */}
//               <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
//                 <div className="flex flex-col gap-1 justify-center">
//                   <label className="ml-2">Attendance</label>
//                   {/* <input
//                     className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
//                     type="text"
//                     value={currentMonthZPoints + "/" + lastMonthZPoints}
//                     onChange={(e) => {
//                       const current = e.target.value.split("/")[0];
//                       const last = e.target.value.split("/")[1];

//                       if (isNaN(parseInt(current))) {
//                         setCurrentMonthZPoints(0);
//                       } else {
//                         setCurrentMonthZPoints(parseInt(current).toString());
//                       }

//                       if (isNaN(parseInt(last))) {
//                         setLastMonthZPoints(0);
//                       } else {
//                         setLastMonthZPoints(parseInt(last).toString());
//                       }
//                     }}
//                   /> */}
//                   <div className="flex items-center justify-center w-[70%]">
//                     <input
//                       className="bg-[#c2bfbf81] text-[#0008] px-2 py-2 rounded-lg"
//                       type="number"
//                       value={currentMonthZPoints}
//                       onChange={(e) => {
//                         setCurrentMonthZPoints(e.target.value)
//                         // const current = e.target.value.split("/")[0];
//                         // const last = e.target.value.split("/")[1];

//                         // if (isNaN(parseInt(current))) {
//                         //   setCurrentMonthZPoints(0);
//                         // } else {
//                         //   setCurrentMonthZPoints(parseInt(current).toString());
//                         // }

//                         // if (isNaN(parseInt(last))) {
//                         //   setLastMonthZPoints(0);
//                         // } else {
//                         //   setLastMonthZPoints(parseInt(last).toString());
//                         // }
//                       }}
//                     />
//                     <p className="mt-3 mx-2">/</p>
//                     <input
//                       className="bg-[#c2bfbf81] text-[#0008] px-2 py-2 rounded-lg "
//                       type="number"
//                       value={lastMonthZPoints}
//                       onChange={(e) => {
//                         setLastMonthZPoints(e.target.value)
//                         // const current = e.target.value.split("/")[0];
//                         // const last = e.target.value.split("/")[1];

//                         // if (isNaN(parseInt(current))) {
//                         //   setCurrentMonthZPoints(0);
//                         // } else {
//                         //   setCurrentMonthZPoints(parseInt(current).toString());
//                         // }

//                         // if (isNaN(parseInt(last))) {
//                         //   setLastMonthZPoints(0);
//                         // } else {
//                         //   setLastMonthZPoints(parseInt(last).toString());
//                         // }
//                       }}
//                     />
//                   </div>
//                 </div>

//                 <div className="flex flex-col gap-1 justify-center">
//                   <label className="ml-2">Joining Date</label>
//                   <div className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-md" style={{ width: '222px' }}>
//                     {formatDate(user.joiningDate)}
//                   </div>
//                 </div>
//               </div>

//               {/* User Status and Due */}
//               <div className="flex flex-row justify-between gap-4 max560:flex-col max560:gap-8">
//                 <div className="flex flex-col gap-1 justify-center">
//                   <label className="ml-2">User Status</label>
//                   <select
//                     className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg "
//                     style={{ width: '222px' }}
//                     value={status}
//                     onChange={(e) => {
//                       setStatus(e.target.value);
//                     }}
//                   >
//                     <option value="Active">Active</option>
//                     <option value="InActive">InActive</option>
//                   </select>
//                 </div>

//                 <div className="flex flex-col gap-1 justify-center">
//                   <label className="ml-2">Due</label>
//                   <input
//                     className="bg-[#c2bfbf81] text-[#0008] px-4 py-2 rounded-lg"
//                     type="text"
//                     value={balance}
//                     onChange={(e) => {
//                       setBalance(e.target.value);
//                     }}
//                   />
//                 </div>
//               </div>

//               <div className="flex flex-col items-center mt-4">
//                 {/* Update Profile Button*/}
//                 <button
//                   className="bg-[#DD934A] rounded-lg py-2 text-white"
//                   onClick={(e) => {
//                     console.log("Before onUpdateUser");
//                     onUpdateUser(e);
//                     console.log("After onUpdateUser");
//                     onClose();
//                   }}
//                 >
//                   <FontAwesomeIcon icon={faUserEdit} className="mr-2" />
//                   Update Profile
//                 </button>
//                 {/* Send Invoice Button */}
//                 <button
//                   className="bg-white rounded-lg py-2 mb-2 flex items-center invbut"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     sendReminder(cognitoId);
//                   }}
//                 >
//                   <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
//                   Send Invoice
//                 </button>

//               </div>

//               {/* Close Button */}
//               <div
//                 className="absolute top-0 right-0 mt-2 mr-2 text-gray-400 hover:text-gray-500 cursor-pointer"
//                 onClick={onClose}
//               >
//                 <CloseIcon />
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UserProfile;