import Pic3 from "../../../Utils/ODISSI/Testimonials/Pic-3.jpg";
import Dum2 from "../../../Utils/ODISSI/Testimonials/Pic-2.jpg";
import Dum1 from "../../../Utils/ODISSI/Testimonials/Pic-1.jpg";


const data = [
{
src: Pic3,
name: "Neha Kachuru",
description:
"As Manasi's oldest student at 25, I embarked on a journey to learn the ancient art of Odissi dance. Manasi not only taught me the nuances of this beautiful dance form but embraced me as a sister. Amidst raising her own children, she fed me, nurtured me, and made me part of her family. I am immensely proud of her as she continues to perform and teach Odissi, spreading its beauty to the world.",
},
{
src: Dum2,
name: "Tanvi Joshi",
description:
"I have been a dedicated student of Manasi Mishra for 20 years, starting at the age of 8. Her expertise in Odissi dance is unparalleled, and I wholeheartedly recommend her as a teacher. With her patient and detail-oriented approach, I have learned and grown immensely. She provides numerous performance opportunities and shares the joy of dancing with her students. Manasi has become like a second mother to me, and I hope to continue learning from her for many more years.",
},
{
src: Dum1,
name: "Jyoti Dave",
description:
"I am immensely grateful for the opportunity to express my deepest appreciation to Manasi, my dance teacher, mentor, and mother figure. Her unwavering passion, belief in my potential, and nurturing nature ignited a fire within me. She not only shaped me as a dancer but also as an individual, instilling in me values of discipline, teamwork, and perseverance. Manasi's artistic visions and her inclusive, loving environment will forever be cherished. Thank you for the gift of love, dance, inspiration, and guidance that have enriched my life. The lessons I've learned from you will always reside in my heart.",
},
];

export default data;

