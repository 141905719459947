const data = {
rows: [
{
title: "1. How to Join?",
content: `Please use Select option to subscribe your coures
subscription. This will take you to PayPal for payment. Once
payment is made you will be asked to authenticate your email
address or phone number. Once that is done you will be able to
add password.`,
},
{
title: "2. How to join online workout?",
content: `The Zoom link is provided for each online class on the
MichiganOdissiDanceAcademy.com . You need to login to access the links. You do not need any password to access the Zoom from the link.`,
},
{
title: "3. What is ODISSI?",
content: `Odissi is a classical Indian dance form originating from Odisha,
India. It is known for its graceful movements, intricate footwork,
hand gestures, and expressive storytelling. With a history dating
back to the 2nd century BCE, Odissi has survived through the centuries
and gained recognition worldwide. Influenced by ancient temple sculptures,
it showcases mythological narratives, devotional themes, and romantic tales.
Odissi dancers, traditionally female, captivate audiences with their fluidity,
precision, and emotive expressions. Revived in the mid-20th century, Odissi has
evolved while preserving its distinct style, incorporating elements from other
dance forms. Today, it continues to enchant and inspire with its rich cultural
heritage and artistic beauty.`,
},

],
};

export default data;
