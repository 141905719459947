import React, { useEffect } from "react";
import Logo from "../../Utils/ODISSI/Logo/logo.png";
import facebook from "../../Utils/Assests/FB.png";
import instagram from "../../Utils/Assests/INSTA.png";
import youtube from "../../Utils/Assests/YOU.png";
import { useNavigate } from "react-router-dom";
import "./New.css";
// import "./Footer.css";

const Footer = (props) => {
    const Navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.initialContent]);

    return (
        <div>
            <div className="w-full h-[2px] bg-[#dd934a]"></div>
            <div className="bg-black">

                <div className="flex flex-wrap justify-between p-6 gap-4 max1008:justify-center max1330:flex-col">
                    <div className="mb-2">
                        <a href="/" className="transition duration-200 flex max1330:justify-center">
                            <img src={Logo} className="w-[10rem] max450:w-[12rem]" alt="" />
                        </a>
                    </div>

                    <ul className="flex gap-12 max950:gap-8 text-white flex-wrap max1330:justify-center max800:justify-center text-left foot">
                        <li className="RussoOne flex flex-col gap-2 items-center text-center text-base">
                            <h2 className="text-lg text-center">Useful Links</h2>
                            <div className="w-full h-0.5" style={{ background: 'linear-gradient(90deg, #DD934A 16.67%, #353534 90.74%)' }}></div>
                            <a
                                className="cursor-pointer text-sm text-center"
                                href="https://www.dropbox.com/s/l8jrcvsd5tw7bw4/Manasi-flyer.pdf?dl=0"
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: 'white', textDecoration: 'none' }}
                            >
                                Odissi Flyer
                            </a>
                            <a
                                className="cursor-pointer text-sm text-center"
                                href="https://www.pinterest.com/search/pins/?q=Odissi%20dance&rs=typed"
                                target={"_blank"}
                                rel="noreferrer"
                                style={{ color: 'white', textDecoration: 'none' }}
                            >
                                Odissi in Pinterest
                            </a>
                        </li>
                        <li className="RussoOne flex flex-col gap-2 items-center text-center text-base">
                            <h2 className="text-lg text-center">Dance Resources</h2>
                            <div className="w-full h-0.5" style={{ background: 'linear-gradient(90deg, #DD934A 16.67%, #353534 90.74%)' }}></div>
                            <a
                                className="cursor-pointer text-sm text-center"
                                href="https://www.pinterest.com/search/pins/?rs=ac&len=2&q=sambalpuri%20dance&eq=sambalpuri%20dance&etslf=17324"
                                target={"_blank"}
                                rel="noreferrer"
                                style={{ color: 'white', textDecoration: 'none' }}
                            >
                                Sambalpuri Folk Dances
                            </a>
                            <a
                                className="cursor-pointer text-sm text-center"
                                href="https://en.wikipedia.org/wiki/Indian_classical_dance"
                                target={"_blank"}
                                rel="noreferrer"
                                style={{ color: 'white', textDecoration: 'none' }}
                            >
                                Indian Classical Dance Wiki
                            </a>
                        </li>
                        <li className="RussoOne flex flex-col gap-2 items-center text-center text-base">
                            <h2 className="text-lg text-center">Affiliated Gyms</h2>
                            <div className="w-full h-0.5" style={{ background: 'linear-gradient(90deg, #DD934A 16.67%, #353534 90.74%)' }}></div>
                            <a
                                className="cursor-pointer text-sm text-center"
                                href="https://happyprancer.com/"
                                target={"_blank"}
                                rel="noreferrer"
                                style={{ color: 'white', textDecoration: 'none' }}
                            >
                                HappyPrancer
                            </a>
                            <a
                                className="cursor-pointer text-sm text-center"
                                href="https://bworkzlive.com/"
                                target={"_blank"}
                                rel="noreferrer"
                                style={{ color: 'white', textDecoration: 'none' }}
                            >
                                BWORKZ
                            </a>
                            <a
                                className="cursor-pointer text-sm text-center"
                                href="https://awsaiapp.com/"
                                target={"_blank"}
                                rel="noreferrer"
                                style={{ color: 'white', textDecoration: 'none' }}
                            >
                                AWSAIAPP
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="py-[1rem] px-8 h-16 bg-[#DD934A] ">
                    <div className="flex bg-[#FFFFFF] justify-between items-center w-[15%] min-w-[8rem] max-w-[10rem] rounded-2xl h-9 p-[1.1rem] mb-[1rem]">
                        <a
                            href="/"
                            rel="noreferrer"
                        >
                            <img
                                src={instagram}
                                alt=""
                                className="hover:mr-2 hover:w-8 hover:h-6 w-6 h-6 "
                            />
                        </a>
                        <a
                            href="https://www.facebook.com/share/idkt6YBW7YcK2Zzt/?mibextid=ox5AEW"
                            target={"_blank"}
                            rel="noreferrer"
                        >
                            <img
                                src={facebook}
                                alt=""
                                className="hover:mr-2 hover:w-8 hover:h-6 w-6 h-6"
                            />
                        </a>
                        <a href="/" rel="noreferrer">
                            <img
                                src={youtube}
                                alt=""
                                className="hover:mr-2 hover:w-8 hover:h-6 w-6 h-6"
                            />
                        </a>
                    </div>
                </div>

                <div className="RussoOne p-2 flex justify-center text-white gap-2 text-xs ">
                    <h5>
                        <p
                            className="cursor-pointer text-center text-[1rem] mt-[4.3px]"
                            onClick={() => {
                                Navigate("/query");
                            }}
                        >
                            Contact Us
                        </p>
                    </h5>
                    <div className="bg-[#DD934A] w-0.5 border-white rounded-md mt-1 h-[1rem]"></div>
                    <h className="text-center text-[1rem]">
                        &copy; All Rights Reserved By Michigan Odissi Dance Academy
                    </h>
                </div>
            </div>
        </div>
    );
};

export default Footer;
