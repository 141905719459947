import React, { useState, useEffect  } from "react";
import { useMediaQuery } from "@mui/material";
import UsersListMobile from "../DashBordMobile/UsersListMobile";
import { useContext } from "react";
import Context from "../../Context/Context";
import './UsersList.css'
import UserNamePng from "../../Utils/images/UsersList/userName.png";
import DetailsPng from "../../Utils/images/UsersList/details.png";
import DuePng from "../../Utils/images/UsersList/due.png";
import AttendancePng from "../../Utils/images/UsersList/attendance.png";
import Pagination from "@mui/material/Pagination";
import 'bootstrap/dist/css/bootstrap.min.css';
// import LeftBanner from "./LeftBanner";
import { API } from "aws-amplify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import Modal from './UserProfile';
import UserProfile from './UserProfile';

const UsersList = ({userCheck, setUserCheck}) => {
const Ctx = useContext(Context);
const [isUserAdd, setIsUserAdd] = useState(false);
const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [phoneNumber, setPhoneNumber] = useState("");
const [status, setStatus] = useState("Active");
const [balance, setBalance] = useState("");
const UtilCtx = useContext(Context).util;
// eslint-disable-next-lineconst pa
const [cognitoId, setCognitoId] = useState("");
const [userStatus, setUserStatus] = useState("all");
// const [selectedUser, setSelectedUser] = useState(null);

const filterUsersByStatus = (status) => {
if (status === "all") {
return Ctx.userList;
}
return Ctx.userList.filter((user) => user.status === status);
};

const availableStatuses = ["all", ...Array.from(new Set(Ctx.userList.map((user) => user.status)))];


console.log(availableStatuses);

const isMobileScreen = useMediaQuery('(max-width: 600px)');

const itemsPerPage = 10;
const [currentPage, setCurrentPage] = useState(1);
const totalPages = Math.ceil(Ctx.userList.length / itemsPerPage);
const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;
const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
const [searchQuery, setSearchQuery] = useState(""); // Step 1: Add state for the search query

const formatDate = (epochDate) => {
const date = new Date(epochDate);
const day = String(date.getDate()).padStart(2, "0");
const month = String(date.getMonth() + 1).padStart(2, "0");
const year = date.getFullYear();
return `${day}/${month}/${year}`;
};

const requestSort = (key) => {
let direction = "ascending";
if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
direction = "descending";
}
setSortConfig({ key, direction });
};


// Step 2: Implement the search functionality
const filter2 = filterUsersByStatus(userStatus);
const filteredUserList = filter2
  .filter((user) => {
    return (
      user?.userName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.emailId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user?.phoneNumber?.includes(searchQuery)
    );
  })
  .slice(startIndex, endIndex);

// ...

// Add this code to reset to page 1 when a search query is changed
useEffect(() => {
  setCurrentPage(1);
}, [searchQuery]);

// const selectedUser = null;
// eslint-disable-next-line
const onUpdateUser = async (e) => {
e.preventDefault();

if (!(name && email && phoneNumber && status && balance)) {
alert("Fill all Fields");
return;
}
if (!name) {
alert("Fill Name");
return;
} else if (!email) {
alert("Fill email");
return;
} else if (!phoneNumber) {
alert("Fill Phone Number");
return;
} else if (!status) {
alert("Fill Status");
return;
} else if (!balance) {
alert("Fill Balance");
return;
}

UtilCtx.setLoader(true);

try {
console.log("started");

await API.put("user", "/admin/update-user/moda", {
body: {
cognitoId: cognitoId,
emailId: email,
userName: name,
phoneNumber: phoneNumber,
status: status,
balance: balance,
},
});

const Users = Ctx.userList.map((item)=>{
if(item.cognitoId === cognitoId){
item.emailId = email;
item.userName = name;
item.phoneNumber = phoneNumber;
item.status = status;
item.balance = balance;

return item;
}else return item;
})

Ctx.setUserList(Users);

alert("User Updated");

setName("");
setEmail("");
setPhoneNumber("");
setStatus("");
setBalance("");

Ctx.onreload();

UtilCtx.setLoader(false);
} catch (e) {
console.log(e);
UtilCtx.setLoader(false);
}
};

const updateUserInList = (updatedUser) => {
// Update the user data in the userList
const updatedList = Ctx.userList.map((user) => {
if (user.cognitoId === updatedUser.cognitoId) {
return updatedUser;
}
return user;
});
Ctx.setUserList(updatedList);
};


const [isModalOpen, setIsModalOpen] = useState(false);

const openModal = () => {
setIsModalOpen(true);

};

const closeModal = () => {
setIsModalOpen(false);
};

const [modalUserData, setModalUserData] = useState();
// Other state variables for user data like cognitoId, name, email, etc.

const onCreateUser = async (e) => {
e.preventDefault();

console.log(name, email, phoneNumber, status, balance);
console.log(name && email && phoneNumber && status && balance);

if (!(name && email && phoneNumber && status && balance)) {
alert("Fill all Fields");
return;
}
if (!name) {
alert("Fill Name");
return;
} else if (!email) {
alert("Fill email");
return;
} else if (!phoneNumber) {
alert("Fill Phone Number");
return;
} else if (!status) {
alert("Fill Status");
return;
} else if (!balance) {
alert("Fill Balance");
return;
}

UtilCtx.setLoader(true);

try {
console.log("started");

await API.post("user", "/admin/create-user/moda", {
body: {
emailId: email,
userName: name,
phoneNumber: phoneNumber,
status: status,
balance: balance,
},
});
Ctx.setUserList([...Ctx.userList, {
emailId: email,
userName: name,
phoneNumber: phoneNumber,
status: status,
balance: balance,
}]);


alert("User Added");

setName("");
setEmail("");
setPhoneNumber("");
setStatus("");
setBalance("");

UtilCtx.setLoader(false);
} catch (e) {
console.log(e);
UtilCtx.setLoader(false);
}
};


return (
<>
{isMobileScreen ? (
<UsersListMobile userCheck={userCheck} setUserCheck={setUserCheck} />
) : (
<div className="w-[99%] flex flex-col items-center pt-6 max536:pt-0 gap-10">
<div className="w-[100%] pt-6 max536:pt-0">
<div className="flex justify-between items-center ml-[9%] mb-0">
<button
className="bg-[#DD934A] py-2  w-[9rem] rounded-[0.2rem] text-white"
onClick={() => {
setUserCheck(1);
setIsUserAdd(true);
}}
>
Add New Member
</button>
<div className="flex gap-3 items-center mr-[8rem] mb-0">
<label className="font-bold" htmlFor="userStatusFilter">
User Status:
</label>
<select
className="rounded-[0.51rem] px-4 bg-[#eceaeaa1]"
id="userStatusFilter"
value={userStatus}
onChange={(e) => setUserStatus(e.target.value)}
>
{availableStatuses.map((status) => (
<option key={status} value={status}>
{status === "all" ? "All" : status}
</option>
))}
</select>
</div>
</div>
{isUserAdd && userCheck === 1 && (
<form className="flex flex-wrap gap-6 items-center justify-center max1250:w-[90%] max900:w-[auto] Sansita mt-4">
<input
required
placeholder="Name"
className="bg-[#eceaeaa1] text-[#0008] sans-sarif px-4 py-1 rounded-lg w-[13rem]"
type={"text"}
value={name}
onChange={(e) => {
setName(e.target.value);
}}
/>
<input
required
placeholder="Email Address"
className="bg-[#eceaeaa1] text-#0008 sans-sarif px-4 py-1 rounded-lg w-[13rem]"
type={"email"}
value={email}
onChange={(e) => {
setEmail(e.target.value);
}}
/>

<input
required
className="bg-[#eceaeaa1] text-#0008 sans-sarif px-4 py-1 rounded-lg w-[13rem]"
placeholder="Phone Number"
type={"number"}
value={phoneNumber}
onChange={(e) => {
setPhoneNumber(e.target.value);
}}
/>
<select
className="bg-[#eceaeaa1] text-#0008 sans-sarif px-4 py-1 rounded-lg w-[13rem]"
onChange={(e) => {
setStatus(e.target.value);
console.log(e.target.value);
}}
value={status}
>
<option value={"Active"}>Active</option>
<option value={"InActive"}>InActive</option>
</select>
<input
required
className="bg-[#eceaeaa1] text-#0008 sans-sarif px-4 py-1 rounded-lg w-[13rem]"
placeholder="Balance"
type={"number"}
value={balance}
onChange={(e) => {
setBalance(e.target.value);
}}
/>
<div className="flex  gap-3 w-full justify-center items-center">
<button
className="sans-sarif bg-[#DD934A] text-white w-[5rem] rounded-lg py-2 "
onClick={() => {
setIsUserAdd(false);
setUserCheck(0);
}}
>
Cancel
</button>
<button
className="sans-sarif bg-[#DD934A] text-white w-[5rem] rounded-lg py-2 "
onClick={onCreateUser}
>
Create
</button>
</div>
</form>
)}
</div>
<div className={`w-[85%] bg-[#eceaeaa1] max536:bg-transparent max536:w-[100%] rounded-3xl p-4 flex flex-col items-center max1050:w-[94vw] mx-[2.5%]`}>
<h2 className="pl-5 font-sans text-[1.4rem] max536:mb-3 max536:text-[1.7rem] sans-serif max536:bg-[#DD934A] font-bold">
Members List
</h2>

{/* Step 4: Create and integrate the search bar */}
<div className="flex w-[94.5%] max1050:w-[30rem] bg-[#eceaeaa1] rounded-md overflow-hidden gap-2">
<input
className="flex-1 p-2 outline-none rounded-md"
type="text"
placeholder="Search members by name, email or phone no."
value={searchQuery}
onChange={(e) => setSearchQuery(e.target.value)}
/>
<button
className="bg-[#DD934A] text-white p-2 rounded-md w-20 "
onClick={() => setSearchQuery("")}
>
Clear
</button>
</div>

<div className="overflow-x-auto w-full">
<ul className="relative px-0 pb-[3rem] w-[95%] max-w-[1700px] mx-auto flex flex-col max536:bg-[#DD934A] rounded-3xl items-center justify-start pt-6 max536:gap-3 max536:h-[calc(100vh-16rem)] max536:bg-gradient-to-b max536:from-[#dad7c6] max536:to-[#fdd00891]">
<li className="w-full flex flex-col items-center justify-center p-2 max536:pt-5 max536:rounded-2xl">
<div className="d-flex justify-content-between w-[98%] max1050:w-[100%] mb-3 font-bold mr-5">
<div className="w-[21.8%]">Name</div>
<div className="w-[22.8%] email-hover" onClick={() => requestSort("email")} style={{ cursor: "pointer" }}>
Email
</div>
<div className="w-[18%]  font-sans ml-[0.5rem] ">Phone</div>
<div className="w-[14%]  font-sans mr-1">Country</div>
<div className="w-[18.5%] font-sans ">Joining Date</div>
<div className="w-[19.5%] font-sans ">Attendance</div>
<div className="w-[8%]  font-sans absolute right-[0rem]">Due</div>
<div className="w-10  font-sans h-10">
<img src={UserNamePng} alt="" className="min536:hidden w-full h-full" />
</div>
<img src={UserNamePng} alt="" className="min536:hidden w-10 h-10" />
<img src={DetailsPng} alt="" className="min536:hidden w-10 h-10 " />
<img src={AttendancePng} alt="" className="min536:hidden w-10 h-10 " />
<img src={DuePng} alt="" className="min536:hidden w-10 h-10" />
</div>
</li>
<div className="overflow-auto max536:w-[96%] w-full">
{filteredUserList.map((user, i) => {
return (
<li
key={user.cognitoId}
className={`w-full flex flex-col gap-[4px] items-center justify-center p-2 max536:bg-[#DD934A] max536:pt-6 max536:rounded-2xl Sansita max536:text-[0.8rem]`}
>
<div className="flex justify-between w-[100%]">
<div className="w-[24%] font-[400] font-sans truncate">{user.userName}</div>
<div className="w-[16%] font-[400] font-sans email-hover" onClick={() => requestSort("email")} style={{ cursor: "pointer" }} title={user.emailId}>
{user.emailId.split("@")[0]}@
</div>
<div className="w-[15%] font-[400] font-sans ml-[4.2rem]">{user.phoneNumber}</div>
<div className="w-[14%] ml-[3rem] font-[400] font-sans max536:hidden">{user.country}</div>
<div className="w-[19%] mr-[1rem] font-[400] font-sans">{formatDate(user.joiningDate)}</div>
<div className="w-[15%] font-[400] font-sans overflow-hidden">
{user.currentMonthZPoints ? user.currentMonthZPoints : 0}/{user.lastMonthZPoints ? user.lastMonthZPoints : 0}
</div>
<div className="w-[8%] h-7 rounded px-2 bg-[#DD934A] text-center ">{user.balance}</div>
<button className="pl-[0.4rem]"
onClick={() => {
console.log('User data before opening modal:', user);
openModal()
setCognitoId(user.cognitoId);
setName(user.userName);
setEmail(user.emailId);
setPhoneNumber(user.phoneNumber);
setStatus(user.status);
setBalance(user.balance);
setModalUserData(user);
console.log('sdsdsdsd', user.joiningDate)

}
}

>
<FontAwesomeIcon icon={faEye} size="sm" />
</button>
<Modal isOpen={isModalOpen} userCheck={userCheck} setUserCheck={setUserCheck} user={modalUserData} onClose={closeModal} >

<UserProfile
isUserAdd={isUserAdd}
userCheck={userCheck}
isOpen={isModalOpen}
onClose={closeModal}
user={modalUserData}
updateUserInList={updateUserInList} // Pass the function
/>
</Modal>
</div>
</li>
)
})}
<div className="absolute bottom-0 flex justify-center items-center w-full">
<Pagination
count={totalPages}
page={currentPage}
onChange={(event, value) => setCurrentPage(value)}
style={{ margin: "0 auto" }}
/>
</div>
</div>
</ul>
</div>
</div>
</div>
)}
{/* {selectedUser && (
<LeftBanner
attendance={selectedUser.currentMonthZPoints || 0}
due={selectedUser.lastMonthZPoints || 0}
/>
)} */}
</>

);
};

export default UsersList;



