import React, { useState, useContext } from "react";
import Context from "../../Context/Context";
import Pagination from "@mui/material/Pagination";
import { API } from "aws-amplify";
import "./UpcomingSessions.css";


const UpcomingSessionsMobile = () => {
    const Ctx = useContext(Context);
    const UtilCtx = useContext(Context).util;
    const [date, setDate] = useState("");

    const [showScheduleForm, setShowScheduleForm] = useState(false);
    const [scheduleFormValues, setScheduleFormValues] = useState({
        instructor: "",
        date: "",
        time: "",
        class: "",
        zoomLink: "",
    });

    // eslint-disable-next-line
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setScheduleFormValues({
            ...scheduleFormValues,
            [name]: value,
        });
    };

    // const [attendance, setAttendance] = useState({ currentMonthZPoints: 0, lastMonthZPoints: 0 });
    // eslint-disable-next-line
    const [due, setDue] = useState(0);

    const [classType, setClassType] = useState("");
    const [zoomLink, setZoomLink] = useState("");
    const [instructorNames, setInstructorNames] = useState("");
    const itemsPerPage = 4;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(Ctx.upcomingClasses.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    // eslint-disable-next-line
    const [editingIndex, setEditingIndex] = useState(-1);
    const instructorNamesArray = ['Manasi', 'Other'];
    const classTypeNameArray = ['Odissi', 'Yoga'];


    const formatDate = (epochDate) => {
        const date = new Date(parseInt(epochDate));
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const period = hours >= 12 ? "PM" : "AM";
        const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
        return `${formattedHours}:${minutes} ${period}`;
    };
    const formatdate = (epochDate) => {
        const date = new Date(epochDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1 to get the correct month
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    const UserCtx = useContext(Context);

    const isMember = UserCtx.userData.userType === "member";


    const onClassUpdated = async (classId, editedInstructorNames, editedClassType) => {
        UtilCtx.setLoader(true);

        try {
            if (!editedInstructorNames) {
                alert("Please select an instructor.");
                UtilCtx.setLoader(false);
                return;
            }

            if (!editedClassType) {
                alert("Please select an Class Type.");
                UtilCtx.setLoader(false);
                return;
            }

            const updatedClasses = Ctx.upcomingClasses.map((c) =>
                c.classId === classId ? { ...c, instructorNames: editedInstructorNames, classType: editedClassType } : c
            );
            await API.put("user", "/admin/edit-schedule-name/moda", {
                body: {
                    classId: classId,
                    instructorNames: editedInstructorNames,
                    classType: editedClassType,
                },
            });
            // alert("Updated");

            // const tempData = [];
            // Ctx.upcomingClasses.forEach((clas, i) => {
            //   if (clas.classId === classId) {
            //     clas.instructorNames = instructorName;
            //   }
            //   tempData.push(clas);
            // });

            Ctx.setUpcomingClasses(updatedClasses);

            setEditingIndex(-1);
            // setInstructorName("");
            // setClassId("");
            // setIsEditing(false);

            UtilCtx.setLoader(false);

        } catch (e) {
            alert(e.message);
            UtilCtx.setLoader(false);
        }
    };


    const onScheduleCreate = async (e) => {
        e.preventDefault();

        try {
            UtilCtx.setLoader(true);

            await API.post("user", "/admin/add-schedule/moda", {
                body: {
                    classType: classType,
                    startTimeEst: new Date(date).getTime(),
                    instructorEmailId: Ctx.userData.emailId,
                    duration: 600,
                    instructorNames: instructorNames,
                    classDescription: "",
                    zoomLink: zoomLink,
                    date: new Date(date).getTime(),
                },
            });

            alert("Class Added");

            const newClass = {
                classType: classType,
                startTimeEst: new Date(date).getTime(),
                instructorNames: instructorNames,
                zoomLink: zoomLink,
                date: new Date(date).getTime(),
            };
            Ctx.setUpcomingClasses([...Ctx.upcomingClasses, newClass]);

            setClassType("");
            setInstructorNames("");
            setZoomLink("");
            setDate("");
        } catch (error) {
            alert(error.message);
        } finally {
            UtilCtx.setLoader(false);
        }
    };

    // Sort the upcoming classes based on the date in descending order
    const sortedUpcomingClasses = Ctx.upcomingClasses.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return (
        <div className="w-full px-2 pb-4">
            {(Ctx.userData.userType === "admin" || Ctx.userData.userType === "instructor") && (
                <div className="container">
                    <button
                        className="filter-button bg-[#2f2f2f] text-[#e1e1e1] w-full m-[1rem] h-[2.1rem] rounded-[0.3rem] text-snow"
                        onClick={() => setShowScheduleForm(!showScheduleForm)}
                    >
                        CREATE A NEW SESSION
                    </button>
                </div>
            )}
            {Ctx.userData.status === "InActive" && Ctx.userData.userType === "member" ? (
                <div> </div>
            ) : <div>
                {isMember && (
                    <div className="bg-[#2f2f2f] text-[#e1e1e1] w-[90%] m-[1rem] pt-[1rem] h-[2.1rem] rounded-[0.3rem] text-snow flex items-center justify-center">
                        <div className="flex">
                            <p className="pr-3">
                                Attendance:{"  "}
                                <span style={{ color: "green" }}>
                                    {UserCtx.userData.currentMonthZPoints ? UserCtx.userData.currentMonthZPoints : 0}
                                </span>
                                {" "}
                                /{" "}
                                <span style={{ color: "red" }}>
                                    {UserCtx.userData.lastMonthZPoints ? UserCtx.userData.lastMonthZPoints : 0}
                                </span>
                            </p>
                        </div>
                        <div className="flex ml-4">
                            <p>{`Due: ${due || 0}`}</p>
                        </div>
                    </div>
                )}

                {showScheduleForm && (
                    <form className="flex flex-col gap-6 w-full Sansita">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[0.5rem]">
                            <input
                                placeholder="Class Type"
                                className="input-field"
                                value={classType}
                                onChange={(e) => setClassType(e.target.value)}
                            />
                            <input
                                placeholder="Instructor Name"
                                className="input-field" // Add the CSS class "attractive-dropdown"

                                value={instructorNames}
                                onChange={(e) => {
                                    setInstructorNames(e.target.value);
                                }}
                            >
                            </input>
                            <input
                                placeholder="Zoom Link"
                                className="input-field"
                                value={zoomLink}
                                onChange={(e) => setZoomLink(e.target.value)}
                            />
                            <input
                                placeholder="Date"
                                type="datetime-local"
                                name="date"
                                value={date}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                                className="input-field"
                            />
                        </div>
                        <button
                            className="sans-serif tracking-wider bg-[#2f2f2f] text-[#e1e1e1] h-[2.4rem] rounded-lg py-2"
                            onClick={onScheduleCreate}
                        >
                            POST
                        </button>
                    </form>
                )}

                <h2 className="text-[1.4rem] mb-5 font-bold text-black-700 mt-5 text-center">Upcoming Sessions</h2>
                <div className="grid gap-[1.4rem] md:gap-4 grid-cols-1 sm:grid-cols-2">
                    {sortedUpcomingClasses
                        .slice(startIndex, endIndex)
                        .map((clas, i) => (
                            <div key={clas.classId} className="class-container">
                                <div
                                    className="bg-gradient-to-r from-#1b7571  to-#1b7571 rounded-lg p-3 md:p-4 shadow-md"
                                    style={{
                                        // background: `linear-gradient(to bottom,rgb(0 255 196), rgb(26 203 164))`,
                                        background: 'linear-gradient(to bottom, #FFD700, #FFA500)',

                                        boxShadow: "0 0px 15px rgba(0, 0, 0, 0.4)",
                                        borderRadius: "1.8rem",
                                    }}
                                >
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <div className="w-[7rem]  attractive-dropdown-container">
                                                {/* Show the dropdowns only to admin and instructor users */}
                                                {Ctx.userData.userType === "admin" || Ctx.userData.userType === "instructor" ? (
                                                    <div className="dropdown-wrapper">
                                                        <select
                                                            className="rounded-[0.51rem] px-1 bg-[#04f8bf00] attractive-dropdown"
                                                            value={clas.instructorNames}
                                                            onChange={(e) => {
                                                                onClassUpdated(clas.classId, e.target.value, clas.classId);
                                                            }}
                                                        >
                                                            {instructorNamesArray.map((name) => (
                                                                <option key={name} value={name}>
                                                                    {name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="dropdown-arrow"></div>
                                                    </div>
                                                ) : (
                                                    <p className="rounded-[0.51rem] pr-4 bg-[#04f8bf00]  max600:bg-[#04f8bf00] ">
                                                        {clas.instructorNames}
                                                    </p>
                                                )}

                                                {Ctx.userData.userType === "admin" || Ctx.userData.userType === "instructor" ? (
                                                    <div className="dropdown-wrapper2">
                                                        <select
                                                            className="rounded-[0.51rem] px-1 bg-[#04f8bf00]  attractive-dropdown2"
                                                            value={clas.classType}
                                                            onChange={(e) => {
                                                                onClassUpdated(clas.classId, clas.instructorNames, e.target.value);
                                                            }}
                                                        >
                                                            {classTypeNameArray.map((classType) => (
                                                                <option key={classType} value={classType}>
                                                                    {classType}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <div className="dropdown-arrow2"></div>
                                                    </div>
                                                ) : (
                                                    <p className="rounded-[0.51rem] bg-[#04f8bf00] max600:bg-[#04f8bf00]">{clas.classType}</p>
                                                )}
                                            </div>


                                            <div className="mb-1 mt-1">Date: {formatdate(parseInt(clas.date))}</div>
                                            <div> Time: {formatDate(clas.date)}</div>
                                        </div>
                                        <div className="ml-2 just">
                                            <button
                                                className="sans-sarif bg-[#2f2f2f] text-[white] px-4 py-[4px] rounded-lg"
                                                onClick={() => window.open(clas.zoomLink, '_blank')}
                                                style={{
                                                    borderRadius: "1rem",
                                                }}
                                            >
                                                Join
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                <div className="flex justify-center items-center mt-4 md:mt-6">
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(event, value) => setCurrentPage(value)}
                    />
                </div>
            </div>}
        </div>


    );
};

export default UpcomingSessionsMobile;


