import React from "react";

import mid_img from "../../Utils/ODISSI/main.png";
import "./Header3.css";



const Header2 = () => {
return (
<div
className="New	flex justify-between max600:h-[69rem]  h-[52rem] text-white blurimg w-[auto] relative pt-[3.5rem] pb-20 pr-5 pl-5 max600:flex-col max600:mx-0 max600:items-start
max600:m-0 max600:w-[90vw] overflow-hidden max800:gap-[1rem]"
>
<div className="p-10 flex flex-col max600:items-center  justify-between  bg-transparent border-y-[0.4rem] rounded-tl-lg rounded-bl-lg border-l-[0.4rem] border-[#DD934A] w-[38vw] h-[45rem] max600:h-auto max600:border-0 max600:w-[100%] max600:gap-8">
<div className="w-[20rem] max800:w-[14rem] max600:w-[100%]  ">
<h1 className="text-[2rem] max800:text-[1.5rem] font-russo max600:text-[1.6rem] capital ml-[2rem]">
Services Offered
</h1>
<ul className="max800:text-[0.8rem] list-disc font-inter">
<li>Odissi Dance Classes: Learn the intricacies of Odissi through our expert-led classes.
</li>
<li>Workshops and Intensive Training: Elevate your skills with specialized programs focusing on technique and repertoire.</li>
<li>Sessions for all levels - Beginners, Intermediate or Pro</li>
<li>Performance Opportunities: Showcase your talent at local events and prestigious festivals.
</li>
</ul>
</div>
<div className="w-[20rem] max800:w-[14rem] max600:w-[100%]">
<h1 className="text-[2rem] max800:text-[1.5rem] font-russo max600:text-[1.6rem] capital ml-[2rem]">
Additional Offerings
</h1>
<ul className="max800:text-[0.8rem] list-disc font-inter">
<li>Choreography Services: Create captivating Odissi routines tailored to your event.</li>
<li>Cultural Outreach Programs: Spread awareness and appreciation of Odissi through educational initiatives</li>
<li>Online Classes: Access convenient virtual learning from anywhere in the world.
</li>
</ul>
</div>

</div>

<div className="Over p-10 flex flex-col max600:items-center max600:pt-0 max950:gap-[11.8rem] gap-[8rem] items-end bg-transparent border-y-[0.4rem] rounded-tr-lg rounded-br-lg border-r-[0.4rem] border-[#DD934A] w-[38vw] h-[45rem]  max600:h-auto max600:border-0 max600:w-[100%]  max800:gap-[7rem] max800:px-[10rem] ">
<div className="w-[20rem]  max800:w-[14rem] max600:w-[100%] ">
<h1 className="text-[2rem] max800:text-[1.5rem] max600:text-[1.6rem] font-russo  max950:pl-[3rem] max600:pl-0 capital ml-[2rem]">
Benefits
</h1>
<ul className="max800:text-[0.8rem] list-disc max950:pl-[2rem] max600:pl-0 font-inter">
<li>
Expert Guidance: Learn from experienced instructors for transformative growth.
</li>
<li>
Stage Presence: Gain confidence through regular performance opportunities.
</li>

</ul>
</div>  <div className="w-[20rem] max800:w-[14rem] max600:w-[100%] ">
<h1 className="text-[2rem] max800:text-[1.5rem] max600:text-[1.6rem] font-russo  max950:pl-[3rem] max600:pl-0 capital ml-[2rem] ">
Resources
</h1>
<ul className="max800:text-[0.8rem] list-disc max950:pl-[3rem] max600:pl-0 font-inter ">
<li>Educational Materials: Access instructional videos and informative articles</li>
<li>Cultural Promotion: Engage in outreach programs to promote Odissi's heritage.</li>
<li> Strength Training</li>
<li> Flexible Learning: Embrace online classes for convenient and global learning.
</li>

</ul>
</div>

</div>

<img
src={mid_img}
className="xs:block hidden absolute left-[53.9%] -translate-x-[68%]  w-[55vw] max1078:-left-[50.9%]  borderbox-hidden bottom-[-39px]  size1"
alt=""
/>
</div>
);
};

export default Header2;

