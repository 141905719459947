// import React, { useContext } from "react";
// import { useNavigate } from "react-router";
// // import "../comp/Subscription.css";
// import Context from "../Context/Context";
// import HappyprancerPaypalMonthly from "../Components/Subscription/HappyprancerPaypalMonthly";
// import HappyprancerRazorpayMonthly from "../Components/Subscription/HappyprancerRazorpayMonthly";
// import NavBar from "../Components/NavBar";
// import Footer from "../Components/Home/Footer";
// import HappyprancerPaypalHybrid from "../Components/Subscription/HappyprancerPaypalHybrid";

// export default function Subscription() {
//   const text = {
//     Heading: 'Monthly Membership Subscription',
//     sub_Heading: 'see what are the pricing in details'
//   };
//   const Ctx = useContext(Context);
//   const UserCtx = useContext(Context).userData;

//   const Navigate = useNavigate();


//   const paymentHandler = (item) => {
//     if (item.currency === "INR" && item.subscriptionType === "Monthly") {
//       return <HappyprancerRazorpayMonthly />;
//     }
//     if (item.currency === "USD" && item.subscriptionType === "Monthly") {
//       return <HappyprancerPaypalMonthly />;
//     }
//     if (item.currency === "USD" && item.subscriptionType === "Hybrid") {
//       return <HappyprancerPaypalHybrid />;
//     }
//   };

//   return (
//     <>
//       <NavBar />
//       <div className="Back text-[1.5rem]  flex  flex-col items-center h-auto min-h-screen max980:h-[auto] justify-center gap-[5rem] pb-20 bg-[#f5f5f5]">
//         <div className="text-center mt-[7rem] sans-sarif ">
//           <h1>{text.Heading}</h1>
//           <h3 className="text-[1rem]">{text.sub_Heading}</h3>
//         </div>
//         <ul className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-28 ">
//           {Ctx.productList.map((item) => {
//             return (
//               <li className="bg-white w-[24rem] py-16 px-8 rounded-[2rem] z-0  flex flex-col items-center gap-8 shadowSubscribe   max450:w-[90vw] max450:gap-4 max450:text-[1rem] max450:min-h-[28rem] max450:h-auto max450:p-12 border-[#225c59] border-[0.1rem]">
//                 <p className="text-[1.6rem]">{item.heading}</p>
//                 <ul className=" text-[1rem] h-auto pl-0 flex flex-col">
//                   {item.provides.map((i) => {
//                     return (
//                       <li >
//                         <p>{i}</p>
//                       </li>
//                     );
//                   })}
//                 </ul>
//                 <h1 className="text-left w-[100%]">
//                   {(item.currency === "INR" ? "₹ " : "$ ") +
//                     parseInt(item.amount) / 100 +
//                     "/" +
//                     item.durationText}
//                 </h1>
//                 {Ctx.isAuth ? (
//                   <div className=" ">
//                     {UserCtx.status === "Active" ? (
//                       <p className="text-[1rem] w-[15rem] px-12 py-2 rounded-2xl text-[#225c59] bg-white border-[#225c59] border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]">
//                         Already Subscribed
//                       </p>
//                     ) : (
//                       <>{paymentHandler(item)} </>
//                     )}
//                   </div>
//                 ) : (
//                   <button
//                     onClick={() => {
//                       Navigate("/signup");
//                     }}
//                     className="w-[15rem] bg-[#225c59] text-white px-12 py-2 rounded-2xl hover:text-[#225c59] hover:bg-white hover:border-[#225c59] hover:border-[0.3rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw]"
//                   >
//                     Sign Up
//                   </button>
//                 )}
//               </li>
//             );
//           })}
//         </ul>
//       </div>
//       <Footer />
//     </>
//   );
// }
import React, {  useRef } from "react";
import { useNavigate } from "react-router";
import "../Components/comp/Subscription.css";
// import Context from "../Context/Context";
import Navbar from "../Components/NavBar"

export default function Subscription() {
// const Ctx = useContext(Context);
const ref = useRef(null);
const Navigate = useNavigate();
const subscriptionDetails = [
{
title: "Subscribe",
price: "$80",
description: "Studio Group Class Monthly",
},
{
title: "Subscribe",
price: "$60",
description: "At Home Group Class Monthly",
},
{
title: "Subscribe",
price: "$140",
description: "Studio Group Class Monthly (Mother & Daughter)",
},
{
title: "Subscribe",
price: "$120",
description: "At Home Group Class Monthly (Mother & Daughter)",
},
{
title: "Subscribe",
price: "$120",
description: "Private Class Monthly",
},
];
return (
<>
<Navbar/>
<section className="Back text-[1.5rem] py-[5rem] flex flex-col items-center h-[140rem] max1414:h-[auto] justify-center gap-[5rem]">
<div className="text-center text-[white] text-[3.4rem] max500:text-[2rem] mt-20 font-bebas-neue">
<div
ref={ref}
style={{ position: "relative", overflow: "hidden" }}
>
<div>
<h1 className="font-Dosis font-bold">
CHECK OUT OUR EXCLUSIVE OFFER!
</h1>
</div>
<div

/>
</div>
<div
ref={ref}
style={{ position: "relative", overflow: "hidden" }}
>
<div>
<h3 className="text-[1.5rem] max500:text-[1rem] font-Dosis">
see what are the pricing in details
</h3>
</div>
<div


/>
</div>
</div>
<div className="flex flex-wrap justify-center items-center w-full max-w-[80rem] gap-28 max800:flex-col">
<div className="flex flex-wrap w-full gap-[10rem] justify-center items-center text-center">
{subscriptionDetails.map((subscription, index) => (
<div
key={index}
style={{ position: "relative", overflow: "hidden" }}
>
<div>
<div className="bg-white w-[30rem] text-center h-[32rem] rounded-[2rem] flex flex-col justify-center items-center gap-4 shadowSubscribe max500:w-[90vw] max450:gap-[1.2rem] max450:text-[1rem] max450:p-12 border-[0.1rem]">
<p className="font-gidugu text-center bg-gradient-radial flex items-center justify-center leading-[1.5rem] h-[107px] w-[303px] max500:text-[1.9rem] text-[2rem] font-Dosis mb-[1.2rem]">
{subscription.title}
</p>
<div className="flex w-[17rem] flex-col text-center gap-5 items-center font-Dosis">
<ul className="list-disc text-[1rem]">
<li>
<p className="font-poppins">
{subscription.description} -{" "}
<span className="font-bold">
{subscription.price}
</span>
</p>
</li>
</ul>
</div>
<button
onClick={() => {
Navigate("/query");
}}
className="w-[15rem] leading-0 textbox px-12 py-2 font-russo rounded-[11px] mt-[2rem] hover:text-[#ffff] hover:bg-[#DD934A] border-[#DD934A] border-[1px] text-[#DD934A] h-[3rem] flex justify-center items-center max450:w-[40vw]"
>
Continue
</button>
</div>
</div>
</div>
))}
</div>
</div>
</section>
</>
);
}

