// import React, { useContext, useState } from "react";
// import { API, Auth } from "aws-amplify";
// import NavBar from "../Components/NavBar";
// import Context from "../Context/Context";
// import DanceAuth from "../Utils/Png/danceAuth.png";
// import { useNavigate } from "react-router-dom";
// import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import Country from "../Components/Auth/Country";
// import "./Login.css";


// const Login = () => {
//   const [email, setEmail] = useState("");
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [password, setPassword] = useState("");
//   const [err, setErr] = useState("");
//   const [countryCode, setCountryCode] = useState();
//   const UtilCtx = useContext(Context).util;
//   const UserCtx = useContext(Context);
//   const [passwordVisible, setPasswordVisible] = useState(false);
//   const [isPhoneNumberLoginValid, setIsPhoneNumberLoginValid] = useState(true);
//   const passwordVisibilityChange = () => {
//     setPasswordVisible((i) => !i);
//   };

//   const Navigate = useNavigate();

//   const onSubmit = async (event) => {
//     event.preventDefault();

//     UtilCtx.setLoader(true);

//     try {
//       const user = await Auth.signIn(
//         `${isPhoneNumberLoginValid ? `+${countryCode}${phoneNumber}` : email}`,
//         password
//       );

//       if (user) {
//         const userdata = await API.get("user", "/user/profile/moda");
//         UserCtx.setUserData(userdata);
//         UserCtx.setIsAuth(true);
//         UtilCtx.setLoader(false);
//         alert("Logged In");
//         console.log(userdata.status);

//         if (userdata.status.trim() === "Active") {
//           Navigate("/dashboard");
//         } else {
//           Navigate("/subscription");
//         }
//       } else {
//         setErr(
//           `Incorrect ${
//             isPhoneNumberLoginValid ? `+${phoneNumber}` : email
//           } and password`
//         );
//         UtilCtx.setLoader(false);
//       }
//     } catch (e) {
//       if (e.toString().split(" code ")[1]?.trim() === "404") {
//         console.log("User Not Found");
//         alert("You Must Sign Up First and use the same email and password");
//         Navigate("/signup?newuser=false");
//         setErr("");
//       } else {
//         setErr(e.message);
//       }
//       UtilCtx.setLoader(false);
//     }
//   };
//   const loginContainerStyle = {
//     width: "100%",
//     marginTop: "1.5rem",
//     paddingBottom: "3rem",
//     borderBottom: "2px solid #DD934A",
//     backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.936), rgba(0, 0, 0, 0.36)), https://images.unsplash.com/photo-1549576490-b0b4831ef60a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)`,
//     height: "50rem",
//     backgroundSize: "cover",

//     };
//   return (
//     <div className="w-screen min-h-screen bg-[#f0efef]">
//       <NavBar />
//       <div className="flex flex-col items-center mt-12 text-black"style={loginContainerStyle}>
//         <h3 className="text-[2rem]">FITNESS</h3>
//         <div className="w-[80%] h-[0.08rem] bg-black flex"></div>
//         <div className="flex w-[100%] gap-16 justify-center items-end mt-40">
//           <form className="w-[50vw] max800:w-[90vw]  max-w-[35rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center ">
//             <h3 className="text-[1.2rem] font-roboto font-bold">
//             Login
//             </h3>
//             <ul className="flex flex-col items-center">
//               <li className="flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative">
//                 <div className="relative">
//                   <input
//                     className="w-[19.5rem] mr-[1.5rem] border-[2px] px-6 py-2 border-[#9d9d9d78]   rounded-2 max500:w-[80vw]"
//                     type="text"
//                     placeholder="Enter Email/Phone No"
//                     value={isPhoneNumberLoginValid ? phoneNumber : email}
//                     onChange={(e) => {
//                       const inputValue = e.target.value;
//                       if (/^\d+$/.test(inputValue)) {
//                         setIsPhoneNumberLoginValid(true);
//                         if(inputValue.length >=0 && inputValue.length <=10 ){
//                           setPhoneNumber(inputValue);
//                         }
//                       } else {
//                         setIsPhoneNumberLoginValid(false);
//                         setEmail(inputValue);
//                       }
//                     }}
//                   />
//                 </div>
//               </li>
//               {isPhoneNumberLoginValid && (
//                 <select
//                   name="countryCode"
//                   id=""
//                   className="w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 border-[#9d9d9d78]   rounded-2 max500:w-[80vw] mt-6"
//                   onChange={(e) => {
//                     setCountryCode(e.target.value.toString());
//                   }}
//                 >
//                   {<Country />}
//                 </select>
//               )}
//               <li className="flex items-center gap-20 mt-6 max500:flex-col max500:gap-2 max500:items-start relative">
//                 <div className="relative">
//                   <input
//                     className="w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw]"
//                     type={passwordVisible ? "text" : "password"}
//                     placeholder="Enter Your Password" // Add the placeholder here
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                   />

//                   {passwordVisible ? (
//                     <AiFillEye
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         passwordVisibilityChange();
//                       }}
//                       className="absolute top-3 right-8 cursor-pointer"
//                       size={"1.25rem"}
//                     />
//                   ) : (
//                     <AiFillEyeInvisible
//                       onClick={(e) => {
//                         e.stopPropagation();
//                         passwordVisibilityChange();
//                       }}
//                       className="absolute top-3 right-8 cursor-pointer"
//                       size={"1.25rem"}
//                     />
//                   )}
//                 </div>
//               </li>
//             </ul>
//             <p
//               className="text-blue-600 text-[0.7rem] mt-6 cursor-pointer"
//               onClick={() => {
//                 Navigate("/forgotpassword");
//               }}
//             >
//               Forgot Password?{" "}
//             </p>
//             {err && <p className="text-[0.8rem] mt-2 text-red-500">{err}</p>}

//             <button
//               className="p-4 py-1 mt-8 mb-3 bg-[#DD934A] text-white rounded-lg"
//               onClick={onSubmit}
//             >
//               Login
//             </button>
//             <p
//               className="text-green-600 cursor-pointer"
//               onClick={() => {
//                 Navigate("/signup");
//               }}
//             >
//              Create a New Account {" "}
//             </p>
//           </form>
//           <img src={DanceAuth} alt="Dance" className="w-[20%] max800:hidden" />
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Login;




import React, { useContext, useState } from "react";
import { API, Auth } from "aws-amplify";
import NavBar from "../Components/NavBar";
import Context from "../Context/Context";
// import DanceAuth from "../Utils/Png/danceAuth.png";
import { useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Country from "../Components/Auth/Country";
import "./Login.css";
// import login from "../Utils/images/raw/Sign_in.png";

const Login = () => {
const [email, setEmail] = useState("");
const [phoneNumber, setPhoneNumber] = useState("");
const [password, setPassword] = useState("");
const [err, setErr] = useState("");
const [countryCode, setCountryCode] = useState();
const UtilCtx = useContext(Context).util;
const UserCtx = useContext(Context);
const [passwordVisible, setPasswordVisible] = useState(false);
const [isPhoneNumberLoginValid, setIsPhoneNumberLoginValid] = useState(true);
const passwordVisibilityChange = () => {
setPasswordVisible((i) => !i);
};

const Navigate = useNavigate();

const onSubmit = async (event) => {
event.preventDefault();

UtilCtx.setLoader(true);

try {
const user = await Auth.signIn(
`${isPhoneNumberLoginValid ? `+${countryCode}${phoneNumber}` : email}`,
password
);

if (user) {
const userdata = await API.get("user", "/user/profile/moda");
UserCtx.setUserData(userdata);
UserCtx.setIsAuth(true);
UtilCtx.setLoader(false);
alert("Logged In");
console.log(userdata.status);

if (userdata.status.trim() === "Active") {
Navigate("/dashboard");
} else {
Navigate("/subscription");
}
} else {
setErr(
`Incorrect ${
isPhoneNumberLoginValid ? `+${phoneNumber}` : email
} and password`
);
UtilCtx.setLoader(false);
}
} catch (e) {
if (e.toString().split(" code ")[1]?.trim() === "404") {
console.log("User Not Found");
alert("You Must Sign Up First and use the same email and password");
Navigate("/signup?newuser=false");
setErr("");
} else {
setErr(e.message);
}
UtilCtx.setLoader(false);
}
};
const loginContainerStyle = {
width: "100%",
marginTop: "1.5rem",
paddingBottom: "3rem",
borderBottom: "2px solid #DD934A",
backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.936), rgba(0, 0, 0, 0.36)), url(https://images.unsplash.com/photo-1549576490-b0b4831ef60a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)`,
height: "50rem",
backgroundSize: "cover",

};
return (
<div className="w-screen min-h-screen bg-[#f0efef]">
<NavBar />
<div className="flex flex-col items-center mt-12 text-black"style={loginContainerStyle}>
{/* <h3 className="text-[2rem] tex">FITNESS</h3>
<div className="w-[80%] h-[0.08rem] bg-white flex"></div> */}
<div className="flex w-[100%] gap-16 justify-center items-end mt-40">
<form className="w-[50vw] max800:w-[90vw]  max-w-[35rem] bg-[#FFFFFF] shadow-2xl rounded-2xl p-4 flex flex-col items-center ">
<h3 className="text-[1.2rem] font-roboto font-bold">
Login
</h3>
<ul className="flex flex-col items-center">
<li className="flex gap-20 mt-8  max500:flex-col max500:gap-2 max500:items-start relative">
<div className="relative">
<input
className="w-[19.5rem] mr-[1.5rem] border-[2px] px-6 py-2 border-[#9d9d9d78]   rounded-2 max500:w-[80vw]"
type="text"
placeholder="Enter Email/Phone No"
value={isPhoneNumberLoginValid ? phoneNumber : email}
onChange={(e) => {
const inputValue = e.target.value;
if (/^\d+$/.test(inputValue)) {
setIsPhoneNumberLoginValid(true);
if(inputValue.length >=0 && inputValue.length <=10 ){
setPhoneNumber(inputValue);
}
} else {
setIsPhoneNumberLoginValid(false);
setEmail(inputValue);
}
}}
/>
</div>
</li>
{isPhoneNumberLoginValid && (
<select
name="countryCode"
id=""
className="w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 border-[#9d9d9d78]   rounded-2 max500:w-[80vw] mt-6"
onChange={(e) => {
setCountryCode(e.target.value.toString());
}}
>
{<Country />}
</select>
)}
<li className="flex items-center gap-20 mt-6 max500:flex-col max500:gap-2 max500:items-start relative">
<div className="relative">
<input
className="w-[19.5rem] mr-[1.5rem] border-[2px] px-[1.5rem] py-2 border-[#9d9d9d78]  rounded-2 max500:w-[80vw]"
type={passwordVisible ? "text" : "password"}
placeholder="Enter Your Password" // Add the placeholder here
value={password}
onChange={(e) => setPassword(e.target.value)}
/>

{passwordVisible ? (
<AiFillEye
onClick={(e) => {
e.stopPropagation();
passwordVisibilityChange();
}}
className="absolute top-3 right-8 cursor-pointer"
size={"1.25rem"}
/>
) : (
<AiFillEyeInvisible
onClick={(e) => {
e.stopPropagation();
passwordVisibilityChange();
}}
className="absolute top-3 right-8 cursor-pointer"
size={"1.25rem"}
/>
)}
</div>
</li>
</ul>
<p
className="text-blue-600 text-[0.7rem] mt-6 cursor-pointer"
onClick={() => {
Navigate("/forgotpassword");
}}
>
Forgot Password?{" "}
</p>
{err && <p className="text-[0.8rem] mt-2 text-red-500">{err}</p>}

<button
className="p-4 py-1 mt-8 mb-3 bg-[#DD934A] text-white rounded-lg"
onClick={onSubmit}
>
Login
</button>
<p
className="text-blue-600 cursor-pointer"
onClick={() => {
Navigate("/signup");
}}
>
Create a New Account {" "}
</p>
</form>
{/* <img src={DanceAuth} alt="Dance" className="w-[20%] max800:hidden" /> */}
</div>
</div>
</div>
);
};

export default Login;



